import { HttpErrorResponse } from '@angular/common/http';
import { BenachrichtigungsModel } from '@dworkflow/shared/model/benachrichtigungs.model';
import { EngineBenachrichtigungsTyp } from '@dworkflow/shared/model/engine-benachrichtigungs.typ.enum';
import { EngineEinstellungenModel } from '@dworkflow/shared/model/engine-einstellungen.model';
import { ErstelleWorkflowverlaufSchrittModel } from '@dworkflow/shared/model/erstelle-workflow-verlauf-schritt.model';
import { ErstelleWorkflowModel } from '@dworkflow/shared/model/erstelle-workflow.model';
import { WorkflowProtokollModel } from '@dworkflow/shared/model/workflow-protokoll.model';
import { guid } from '@dworkflow/shared/utility/guid';
import { createAction, props } from '@ngrx/store';
import { BenachrichtigungenModel } from '../shared/model/benachrichtigungen.model';
import { EditWorkflowInformationenModel } from '../shared/model/edit-workflowinformationen.model';
import { HaftnotizModel } from '../shared/model/haftnotiz.model';
import { WorkflowInformationenFuerTabelleModel } from '../shared/model/workflow-informationen-fuer-tabelle.model';
import { LoadingStatus } from '../shared/model/workflow-loading-status.enum';
import { WorkflowModel } from '../shared/model/workflow.model';
import { WorkflowverlaufModel } from '../shared/model/workflowverlauf.model';

/**
 * Workflow-actions
 */

// Laden
export const loadWorkflow = createAction('[Engine] Load Workflow', props<{ workflowId: guid }>());

export const loadWorkflowSucceeded = createAction(
  '[Engine] Load Workflow Succeeded',
  props<{ workflowId: guid; workflow: WorkflowModel }>()
);

export const loadWorkflowFailed = createAction(
  '[Engine] Load Workflow Failed',
  props<{
    workflowId: guid;
    loadingStatus: LoadingStatus;
    loadingErrorDetails: string;
  }>()
);

export const requestLoadWorkflowInformationenFuerTabelle = createAction(
  '[Engine] Request Load Workflow Informationen für Tabelle',
  props<{ workflowId: guid }>()
);

export const loadWorkflowInformationenFuerTabelleSucceeded = createAction(
  '[Engine] Load Workflows Informationen für Tabelle Succeeded',
  props<{ workflows: WorkflowInformationenFuerTabelleModel[] }>()
);

export const loadWorkflowInformationenFuerTabelleFailed = createAction(
  '[Engine] Load Workflows Informationen für Tabelle Failed',
  props<{
    error: HttpErrorResponse;
  }>()
);

export const loadMeineWorkflows = createAction('[Engine] Load Meine Workflows');

export const loadMeineWorkflowsSucceeded = createAction(
  '[Engine] Load Meine Workflows Succeeded',
  props<{ workflows: WorkflowInformationenFuerTabelleModel[] }>()
);

export const loadMeineWorkflowsFailed = createAction(
  '[Engine] Load Meine Workflows Failed',
  props<{ error: HttpErrorResponse }>()
);

export const loadMeineWorkflowsFuerStartseite = createAction(
  '[Engine] Load Meine Workflows fuer Startseite'
);

export const loadMeineWorkflowsFuerStartseiteSucceeded = createAction(
  '[Engine] Load Meine Workflows fuer Startseite Succeeded',
  props<{ workflows: WorkflowInformationenFuerTabelleModel[] }>()
);

export const loadMeineWorkflowsFuerStartseiteFailed = createAction(
  '[Engine] Load Meine Workflows fuer Startseite Failed',
  props<{ error: HttpErrorResponse }>()
);

export const loadAlleWorkflows = createAction('[Engine] Load Alle Workflows');

export const loadAlleWorkflowsSucceeded = createAction(
  '[Engine] Load Alle Workflows Succeeded',
  props<{ workflows: WorkflowInformationenFuerTabelleModel[] }>()
);

export const loadAlleWorkflowsFailed = createAction(
  '[Engine] Load Alle Workflows Failed',
  props<{ error: HttpErrorResponse }>()
);

export const loadAlleWorkflowsAnzahl = createAction('[Engine] Load Alle Workflows Anzahl');

export const loadAlleWorkflowsAnzahlSucceeded = createAction(
  '[Engine] Load Alle Workflows Anzahl Succeeded',
  props<{ workflowAnzahl: number }>()
);

export const loadAlleWorkflowsAnzahlFailed = createAction(
  '[Engine] Load Alle Workflows Anzahl Failed',
  props<{ error: HttpErrorResponse }>()
);

export const loadVertretungenWorkflows = createAction(
  '[Engine] Load Vertretungen-Workflows',
  props<{ principalId: number }>()
);

export const loadVertretungenWorkflowsFailed = createAction(
  '[Engine] Load Vertretungen-Workflows Failed',
  props<{
    principalId: number;
    error: HttpErrorResponse;
  }>()
);

export const loadVertretungenWorkflowsSucceeded = createAction(
  '[Engine] Load Vertretungen-Workflows Succeeded',
  props<{
    principalId: number;
    workflows: WorkflowInformationenFuerTabelleModel[];
  }>()
);

// Workflow erstellen
export const createWorkflow = createAction(
  '[Engine] Create Workflow',
  props<{ workflow: ErstelleWorkflowModel; starteWorkflowverlaufDelayed: boolean }>()
);

export const createWorkflowSucceeded = createAction(
  '[Engine] Create Workflow Succeeded',
  props<{ workflow: WorkflowModel; starteWorkflowverlaufDelayed: boolean }>()
);

export const createWorkflowFailed = createAction(
  '[Engine] Create Workflow Failed',
  props<{ workflowId: guid; error: HttpErrorResponse }>()
);

// Verlauf starten
export const starteWorkflowverlauf = createAction(
  '[Engine] Starte Workflowverlauf',
  props<{ workflowId: guid; workflowverlaufId: guid }>()
);

export const starteWorkflowverlaufSucceeded = createAction(
  '[Engine] Starte Workflowverlauf Succeeded',
  props<{ workflowverlaufId: guid }>()
);

export const starteWorkflowverlaufFailed = createAction(
  '[Engine] Starte Workflowverlauf Failed',
  props<{ error: HttpErrorResponse; workflowverlaufId: guid }>()
);

// Verlauf anpassen
export const editWorkflowverlauf = createAction(
  '[Engine] Edit Workflowverlauf',
  props<{
    workflowTitel: string;
    navigateTo: string;
    verlaufId: guid;
    schritte: ErstelleWorkflowverlaufSchrittModel[];
    isFaelligkeitsAnpassung: boolean;
    hauptfaelligkeit: Date;
  }>()
);

export const editWorkflowverlaufSucceeded = createAction(
  '[Engine] Edit Workflowverlauf Succeeded',
  props<{ workflowverlauf: WorkflowverlaufModel }>()
);

export const editWorkflowverlaufFailed = createAction(
  '[Engine] Edit Workflowverlauf Failed',
  props<{ workflowverlauf: WorkflowverlaufModel; error: HttpErrorResponse }>()
);

// Stornieren
export const storniereWorkflow = createAction(
  '[Engine] storniere Workflow',
  props<{ workflowId: guid; titel: string; kommentar: string }>()
);

export const storniereWorkflowFailed = createAction(
  '[Engine] Storniere Workflow Failed',
  props<{ error: HttpErrorResponse }>()
);

export const storniereWorkflowSucceed = createAction(
  '[Engine] Storniere Workflow Succeeded',
  props<{ workflowId: guid }>()
);

// Löschen
export const loescheWorkflow = createAction(
  '[Engine] Loesche Workflow',
  props<{ workflowId: guid }>()
);

export const loescheWorkflowFailed = createAction(
  '[Engine] Loesche Workflow Failed',
  props<{ error: HttpErrorResponse }>()
);

export const loescheWorkflowSucceed = createAction(
  '[Engine] Loesche Workflow Succeeded',
  props<{ workflowId: guid }>()
);

/**
 * Haftnotiz-actions
 */

// Löschen
export const loescheHaftnotiz = createAction(
  '[Engine] Loesche Haftnotiz',
  props<{ workflowId: guid }>()
);

export const loescheHaftnotizSucceeded = createAction(
  '[Engine] Loesche Haftnotiz Succeeded',
  props<{ workflowId: guid }>()
);

export const loescheHaftnotizFailed = createAction(
  '[Engine] Loesche Haftnotiz Failed',
  props<{ error: HttpErrorResponse }>()
);

// Speichern
export const speichereHaftnotiz = createAction(
  '[Engine] Speichere Haftnotiz',
  props<{ workflowId: guid; haftnotiz: string }>()
);

export const speichereHaftnotizSucceeded = createAction(
  '[Engine] Speichere Haftnotiz Succeeded',
  props<{ workflowId: guid; haftnotiz: HaftnotizModel }>()
);

export const speichereHaftnotizFailed = createAction(
  '[Engine] Speichere Haftnotiz Failed',
  props<{ workflowId: guid; error: HttpErrorResponse }>()
);

/**
 * WorkflowInformationen
 */

export const speicherWorkflowInformationen = createAction(
  '[Engine] Speichere WorkflowInformationen',
  props<{ workflowInformationen: EditWorkflowInformationenModel }>()
);

export const speicherWorkflowInformationenSucceeded = createAction(
  '[Engine] Speichere WorkflowInformationen Succeeded',
  props<{ workflow: WorkflowModel }>()
);

export const speicherWorkflowInformationenFailed = createAction(
  '[Engine] Speichere WorkflowInformationen Failed',
  props<{ workflowId: guid; error: HttpErrorResponse }>()
);
/**
 * Einstellungen-actions
 */

export const loadEinstellungen = createAction('[Engine] Load Einstellungen');

export const loadEinstellungenSucceeded = createAction(
  '[Engine] Load Einstellungen Succeeded',
  props<{ einstellungen: EngineEinstellungenModel }>()
);

export const loadEinstellungenFailed = createAction(
  '[Engine] Load Einstellungen Failed',
  props<{ error: HttpErrorResponse }>()
);

export const updateEinstellungen = createAction(
  '[Engine] Update Einstellungen',
  props<{ einstellungen: EngineEinstellungenModel }>()
);

export const updateEinstellungenSucceeded = createAction(
  '[Engine] Update Einstellungen Succeeded',
  props<{ einstellungen: EngineEinstellungenModel }>()
);

export const updateEinstellungenFailed = createAction(
  '[Engine] Update Einstellungen Failed',
  props<{ error: HttpErrorResponse }>()
);

export const loadBenachrichtigungen = createAction('[Engine] Load Benachrichtigungen');

export const loadBenachrichtigungenSucceeded = createAction(
  '[Engine] Load Benachrichtigungen Succeeded',
  props<{ benachrichtigungen: BenachrichtigungenModel }>()
);

export const loadBenachrichtigungenFailed = createAction(
  '[Engine] Load Benachrichtigungen Failed',
  props<{
    error: HttpErrorResponse;
  }>()
);

export const updateBenachrichtigung = createAction(
  '[Engine] Update Benachrichtigung',
  props<{
    typ: EngineBenachrichtigungsTyp;
    betreff: string;
    inhalt: string;
    versandAktiviert: boolean;
  }>()
);

export const updateBenachrichtigungSucceeded = createAction(
  '[Engine] Update Benachrichtigung Succeeded',
  props<{
    model: BenachrichtigungsModel;
  }>()
);

export const updateBenachrichtigungFailed = createAction(
  '[Engine] Update Benachrichtigung Failed',
  props<{
    error: HttpErrorResponse;
  }>()
);

/**
 * Protokoll actions
 */
export const loadProtokoll = createAction('[Engine] Load Protokoll', props<{ workflowId: guid }>());

export const loadProtokollSucceeded = createAction(
  '[Engine] Load Protokoll Succeeded',
  props<{ protokoll: WorkflowProtokollModel }>()
);

export const loadProtokollFailed = createAction(
  '[Engine] Load Protokoll Failed',
  props<{ workflowId: guid; loadingStatus: LoadingStatus; loadingErrorDetails: string }>()
);

export const deleteProtokoll = createAction(
  '[Engine] Delete Protokoll',
  props<{ workflowId: guid }>()
);
